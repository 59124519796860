import { FetcherWithComponents } from '@remix-run/react';
import qs from 'qs';
import { ApiResponseMessage } from '@server/models/common.models';

export const qsParseClient = (params: any) => {
    const parsedParams = qs.parse(params, {
        ignoreQueryPrefix: true,
        arrayLimit: Infinity,
    });
    return parsedParams;
};

export const qsStringifyClient = (params: any) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetcherType = (fetcher: FetcherWithComponents<ApiResponseMessage>) => {
    // fetcher.type === "init"
    if (fetcher.state === 'idle' && fetcher.data == null) {
        return 'init';
    }

    // fetcher.type === "done"
    if (fetcher.state === 'idle' && fetcher.data != null) {
        if (fetcher.data.status && fetcher.data.status === 200) {
            return 'success';
        } else if (fetcher.data.status && fetcher.data.status !== 200) {
            return 'serverError';
        }

        return 'done';
    }

    // fetcher.type === "actionSubmission"
    if (fetcher.state === 'submitting') {
        return 'actionSubmission';
    }

    // fetcher.type === "actionReload"
    if (
        fetcher.state === 'loading' &&
        fetcher.formMethod != null &&
        fetcher.formMethod != 'GET' &&
        fetcher.data != null
    ) {
        return 'actionReload';
    }

    // fetcher.type === "actionRedirect"
    if (
        fetcher.state === 'loading' &&
        fetcher.formMethod != null &&
        fetcher.formMethod != 'GET' &&
        fetcher.data == null
    ) {
        return 'actionRedirect';
    }

    // fetcher.type === "loaderSubmission"
    if (fetcher.state === 'loading' && fetcher.formMethod === 'GET') {
        return 'loaderSubmission';
    }

    // fetcher.type === "normalLoad"
    if (fetcher.state === 'loading' && fetcher.formMethod == null) {
        return 'normalLoad';
    }

    // If none of the above conditions are met, return undefined
    return undefined;
};
